import React from 'react';
import HomeImg from '../images/home_2.jpg';

const About = () => {
    return (
		<div className="page-content">
			<h2 className="w3-center title-h">About Us</h2>

			<div className="wrapper">
				<img className="page-img" src={HomeImg} alt="house" />
			</div>

			<div className="w3-container wrapper">
				<p>
					Silver Rock Stucco is a family owned and operated business and has been for 25 plus years!
					Our top priority is quality and your satisfaction.
					We do stucco projects all over the East and West Kootenays from Creston to Invermere.
				</p>
				
				<p>
					Besides our local Cranbrook area we do projects in Creston, Rosland, Trail,
					and everywhere in between. Text or Call us at 
					<a href = "sms:12504318135"> (250) 431-8135 </a> and tell us a little about
					your project. We would like to hear about your stucco needs and love to
					give you a quote.
				</p>

				<p>
					Silver Rock has extensive experience with all stucco systems 
					from standard Imasco to the EIFS system. However, we have 
					developed our own system which utilizes the best of both systems.
				</p>

				<p>
					We also do parging on concrete and foam foundations.
					The parging is to hide surface imperfections from formwork
					or cracks to give the foundation a decorative finish.
				</p>
			</div>
		</div>
    );
}

export default About;