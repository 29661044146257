import React from 'react';

const Footer = () => {
    return (
        <footer className="w3-container">
            Silver Rock Stucco <br></br>
            Call/Text: 250 431-8135 <br></br>
            Cranbrook B.C | East Kootenays | West Kootenays <br></br>
            Local Quality Stucco
        </footer>
    );
}

export default Footer;