import React from 'react';
import Home9 from '../images/home_9.jpg';

const Home = () => {
    return (
        <div className="page-content">
            <h2 className="w3-center title-h">Top Quality Stucco</h2>

            <div className="wrapper">
                <img className="page-img" src={Home9} alt="house" />
            </div>

            <div className="w3-container wrapper">
                <p>
                    Doing quality stucco since 1991. We serve the Cranbrook area and
                    everything between the East and West Kootenays.
                    Text now to <a href = "sms:12504318135"> (250) 431-8135 </a> 
                    or submit a simple form to get a free quote.
                </p>

                <p>
                    No job is too big or too small. We can do repairs, match existing textures,
                    or new construction on residential or commercial projects and provide a
                    variety of textures and colors.
                </p>

                <p>
                    Silver Rock has extensive experience with all stucco systems from standard Imasco
                    to the EIFS system. However, we have developed our own system which utilizes
                    the best of both systems.
                    We haven't forgot about your foundations either, parging is a great option to give your foundaton
                    a nice decorative and finished look.
                </p>
            </div>
        </div>
    );
}

export default Home;