import React from 'react';
import { BrowserRouter as Router, Redirect, Switch, Route } from 'react-router-dom';
import Nav from './Nav';
import Home from './Home';
import About from './About';
import Gallery from './Gallery';
import Contact from './Contact';
import Footer from './Footer';
import '../css/App.css';
import '../css/w3.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Nav />
        <div className="page-top page">
          <Switch>
            <Route path='/' exact component={Home}/>
            <Route path='/home.php' component={Home}/>
            <Redirect from='/home' exact to='/'/>
            <Redirect from='/home.php' to='/'/>

            <Route path='/about' component={About}/>
            <Redirect from='/about.php' to='/about'/>

            <Route path='/gallery' component={Gallery}/>
            <Redirect from='/gallery.php' to='/gallery'/>

            <Route path='/contact' component={Contact}/>
            <Redirect from='/contact.php' to='/contact'/>
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
