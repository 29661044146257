import React from 'react';
import '../css/form.css';

const ContactForm = () => {
    return (
        <form id="fs-frm" className="w3-container" name="simple-contact-form"
            acceptCharset="utf-8" 
            action="https://formspree.io/acltdstucco1111@gmail.com"
            method="post"
        >
            <fieldset id="fs-frm-inputs">
                {/* Name */}
                <label htmlFor="full-name">Full Name</label>
                <input type="text" name="name" id="full-name" placeholder="First and Last" required="" />
                {/* Email */}
                <label htmlFor="email-address">Email Address</label>
                <input type="email" name="_replyto" id="email-address" placeholder="email@gmail.com" required=""></input>
                {/* Message */}
                <label htmlFor="message">Message</label>
                <textarea rows="5" name="message" id="message" placeholder="Your message here." required=""></textarea>
                <input type="hidden" name="_subject" id="email-subject" value="Contact Form Submission"></input>
            </fieldset>
            <input type="submit" value="Submit" />
        </form>
    );
}

export default ContactForm;