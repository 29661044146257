import React from 'react';
import ImageGallery from 'react-image-gallery';
import Home1 from '../images/home_1.jpg';
import Home2 from '../images/home_2.jpg';
import Home3 from '../images/home_3.jpg';
import Home4 from '../images/home_4.jpg';
import Home5 from '../images/home_5.jpg';
import Home6 from '../images/home_6.jpg';
import Home7 from '../images/home_7.jpg';
import Home8 from '../images/home_8.jpg';
import Home9 from '../images/home_9.jpg';
import Home10 from '../images/home_10.jpg';
import Home11 from '../images/home_11.jpg';
import Home12 from '../images/home_12.jpg';
import Home13 from '../images/home_13.jpg';
import Home14 from '../images/home_14.jpg';
import Home15 from '../images/home_15.jpg';
import Home16 from '../images/home_16.jpg';
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
    {
        original: Home2,
    },
    {
        original: Home1,
    },
    {
        original: Home3,
    },
    {
        original: Home4,
    },
    {
        original: Home5,
    },
    {
        original: Home6,
    },
    {
        original: Home7,
    },
    {
        original: Home8,
    },
    {
        original: Home9,
    },
    {
        original: Home10,
    },
    {
        original: Home11,
    },
    {
        original: Home12,
    },
    {
        original: Home13,
    },
    {
        original: Home14,
    },
    {
        original: Home15,
    },
    {
        original: Home16,
    }
]

const Gallery = () => {
    return (
        <div className="wrapper page-content">
            <h2 className="w3-center title-h">
                Gallery
            </h2>
            <p className="w3-center">
                Local Cranbrook B.C. Stucco
            </p>
            <div className="w3-center">
                <ImageGallery 
                    items={images}
                    showThumbnails={false}
                    lazyLoad={true}
                    showFullscreenButton={false}
                    showPlayButton={false}
                />
            </div>

            <div className="w3-left w3-container">
            
                <p>
                    Check out a few of our local stucco jobs. Quality and your satisfaction
                    is our number one priority. Let us know if you have any questions about some of our past work.
                </p>

                <p>
                    Text or Call us at +1 (250) 431-8135 and we would be happy to talk with you.
                </p>

            </div>
        </div>
    );
}

export default Gallery;