import React from 'react';
import ContactForm from './ContactForm';


const Contact = () => {
    return (
        <div className="page-content wrapper">
            <h2 className="w3-center title-h">
                Contact Us
            </h2>
            <div className="w3-container w3-left">
                <p>
                    Contact us for any inquiries, feedback, or for any information
                    related to our stucco business. We would love
                    to answer your questions and hear your suggestions.
                </p>

                <p>
                    Text or Call Brian Palmer at: <a href = "sms:12504318135"> (250) 431-8135</a>
                </p>
                <p>
                    If you would like a quote for your stucco project, just fill out this simple form. 
                    In the message section give us some details about the project including the dimensions
                    and we will get back to you as soon as possible. <br></br>
                    Or if you want, just send us a message with any questions you may have.
                </p>
            </div>
            <ContactForm />
        </div>
    );
}

export default Contact;