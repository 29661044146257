import React from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {
    //TODO: USe the state to update the sidebar display
    return (
        <div>
            <div id="side-bar" className="w3-sidebar w3-bar-block w3-border-right">
                {/* <button onClick={w3_close} className="w3-bar-item w3-large w3-button">
                    Close &times;
                </button> */}

                <Link to="/">
                    <div className="w3-bar-item w3-button w3-padding-32 w3-center" onClick={w3_close}>
                        Home
                    </div>
                </Link>
                <Link to="/about">
                    <div className="w3-bar-item w3-button w3-padding-32 w3-center" onClick={w3_close}>
                        About
                    </div>
                </Link>
                <Link to="/gallery">
                    <div className="w3-bar-item w3-button w3-padding-32 w3-center" onClick={w3_close}>
                        Gallery
                    </div>
                </Link>
                <Link to="/contact">
                    <div className="w3-bar-item w3-button w3-padding-32 w3-center" onClick={w3_close}>
                        Contact
                    </div>
                </Link>
            </div>
      
            <div id="header" className="w3-row w3-top">
                <div className="w3-col s4">

                    <button className="w3-button" onClick={toggleMenu}>
                        <span className="w3-large">☰</span>
                        <span> <b id="menu-txt">Menu</b> </span>
                    </button>

                </div>

                <div id="srs-title" className="w3-col w3-container w3-right-align s8">
                    Silver Rock Stucco
                </div>

                {/* <a id="phone-number" href="tel:250-431-8135">Text/Call: (250) 431-8135</a> */}

            </div>

        </div>
    );
}

//TODO: Remove this and put it in the state
const w3_open = () => {
    document.getElementById("side-bar").style.display = "block";
    document.getElementById("menu-txt").innerHTML = "Close";
}

//TODO: Remove this and put it in the state
const w3_close = () => {
    document.getElementById("side-bar").style.display = "none";
    document.getElementById("menu-txt").innerHTML = "Menu";
}

const toggleMenu = () => {
    if (document.getElementById("side-bar").style.display === "block") {
        w3_close();
    } else {
        w3_open();
    }
}

export default Nav;